@import '../../../App.scss';

.testimonials {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.5rem;
  ul {
    list-style: none;
    margin-top: 1rem;
    display: flex;
    align-items: center;
  }
  p {
    border: 1px solid $medium-color;
    border-radius: 5px;
    padding: 0.5rem;
    height: 90px;
  }
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
}
//Smart Phones
@media(max-width: 500px) {
  #about-d .testimonials,
  .items {
    grid-template-columns: 1fr;
  }
}
