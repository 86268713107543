@import './../../App.scss';
//Header ::content
.header-content {
  text-align: center;
  padding-top: 10%;
  h1 {
    font-size: 4rem;
    line-height: 1.2;
  }
  p {
    font-size: 1.3rem;
    margin-bottom: 2rem;
  }
}
.btn-light {
  border-radius: 50%;
}
