@import '../../../App.scss';

#contact {
  &-a {
    .text-fields {
      display: grid;
      grid-template-areas:
        'name email'
        'subject phone'
        'message message';
      grid-gap: 1.2rem;
      margin-bottom: 1.2rem;
      .name-input {
        grid-area: name;
        width: 93%;
        padding: 0.5rem 1rem;
      }
      .email-input {
        grid-area: email;
        width: 93%;
        padding: 0.5rem 1rem;
      }
      .subject-input {
        grid-area: subject;
        width: 93%;
        padding: 0.5rem 1rem;
      }
      .phone-input {
        grid-area: phone;
        width: 93%;
        padding: 0.5rem 1rem;
      }
      .message-input {
        grid-area: message;
        height: 100px;
      }
      .warning {
        color: $orange-color;
      }
    }
    button[type='submit'] {
      width: 50%;
      border-radius: 50%;
    }
  }
}
//Smart Phones
@media(max-width: 500px) {
  #contact {
    &-a {
        .text-fields {
           display: grid;
          // grid-template-columns: 1fr;
          grid-template-areas:
            'name'
            'subject'
            'email'
            'phone'
            'message';
      }
    }
  }
}
