// @import '~antd/dist/antd.css';
@import './index.scss';


//General Styles
* {
  margin: 0;
  padding: 0;
}

.container {
   max-width: $website-width;
   padding: 0 1.5rem;
   margin: auto;
  overflow: hidden;
}

body {
  font-family: 'Dosis', sans-serif;
  line-height: 1.6;
  background: $white-color;
}
//To be shared by all buttons
%btn-shared {
  display: inline-block;
  padding: 0.8rem 2rem;
  transition: all 0.5s;
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: $dark-color;
}

ul {
  list-style: none;
}

h2, h3, h4 {
  text-transform: uppercase;
}

img {
  width: 100%
}

.lead {
  border-bottom: 3px solid $light-color;
  font-size: 1.3rem;
  margin-bottom: 2rem;
}
.sectiion-title {
  font-size: 2rem;
  display: block;
  padding-bottom: 0.5rem;
  font-weight: 100;
  text-transform: uppercase;
}
.bottom-line {
  height: 2px;
  width: 3rem;
  background: $orange-color;
  display: block;
  margin: 0 auto 1rem auto;
}
//Backgrounds
.bg {
  &-main {
    background: $orange-color;
    color: set-text-color($orange-color)
  }
  &-dark {
    background: $dark-color;
    color: set-text-color($dark-color)
  }
  &-light {
    background: $light-color;
    color: set-text-color($light-color)
  }
  &-medium {
    background: $medium-color;
    color: set-text-color($medium-color)
  }
}
//Progress
.progress {
  overflow: hidden;
  height: 20px;
  background: $medium-color;
  border-radius: 5px;
  margin-bottom: 0.3rem;
  div {
    height: 100%;
    color: $white-color;
    text-align: center;
    background: $orange-color;
  }
}
//Padding
.py {
  &-1 { padding: 1rem 0; }
  &-2 { padding: 2rem 0; }
  &-3 { padding: 3rem 0; }
  &-4 { padding: 4rem 0; }
}
//Margin
.my {
  &-1 { padding: 1rem 0; }
  &-2 { padding: 2rem 0; }
  &-3 { padding: 3rem 0; }
  &-4 { padding: 4rem 0; }
}

//buttons


.btn {
  &-main {
    @extend %btn-shared;
    color: $black-color;
    background-color: $main-color;
  }
  &-light {
    @extend %btn-shared;
    color: $black-color;
    background-color: $light-color;
  }
  &-dark {
    @extend %btn-shared;
    color: $light-color;
    background-color: $dark-color;
  }
}
//This would apply to any class with 'btn-'
button[class^='btn-']:hover,
a[class^='btn-']:hover,
input[class^='btn-']:hover {
  background-color: $orange-color;
}
.text-center {
  text-align: center;
}
