@import '../../App.scss';

#main-footer {
  background: $dark-color;
  color: $white-color;
  height: 5rem;
  .footer-content {
    display: flex;
    justify-content: space-between;
    height: 5rem;
    align-items: center;
    .social .fab{
      margin-right: 1rem;
      color: $white-color;
      border: 2px $white-color solid;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      line-height: 20px;
      text-align: center;
      padding: 0.5rem;
      transition: all 1s;
      &:hover {
        background: $orange-color;
        height: 22px;
        width: 22px;
        line-height: 22px;
      }
    }
  }
}
//Smart Phones
@media(max-width: 500px) {
  #main-footer {
    height: 7rem;
    .footer-content {
      flex-direction: column;
      padding: 1rem;
      height: 5rem;
    }
  }
}
