@import '../../../App.scss';
#home {
  &-b {
    .stats {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(4, 1fr);
      li {
        line-height: 2;
        &.stats-title {
          font-size: 1.5em;
        }
        &.stats-number {
          font-size: 2rem;
          font-weight: bold;
        }
      }
      div {
        padding: 3rem 0;
        &:nth-child(odd) {
          background-color: $light-color;
        }
        &:nth-child(even) {
          background-color: $medium-color;
        }
      }
    }
  }
}
//Smart Phones
@media(max-width: 500px) {
  #home-b .stats,
  .items {
    grid-template-columns: 1fr;
  }
  #home-b .stats div {
    padding: 2rem 0 1rem 0;
  }
}
