@import '../../../App.scss';

#about {
  &-a {
    .about-info {
      display: grid;
      grid-gap: 1.2rem;
      grid-template-areas:
        'bioimage bio bio'
        'aw1 aw2 aw3';
      //Grid area
      .bio-image {
        grid-area: bioimage;
        width: 330px;
        height: 300px;
      }
      .bio {
        grid-area: bio;
        border-left: 3px solid $green-color;
        padding: 0.8rem;
      }
      .award-1 {
        grid-area: aw1;
      }
      .award-2 {
        grid-area: aw2;
      }
      .award-3 {
        grid-area: aw3;
      }
      .award-1 .fas, .award-2 .fas, .award-3 .fas {
        color: $green-color;
        margin: 1rem;
      }
    }
  }
}
//Smart Phones
@media(max-width: 500px) {
  #about {
    &-a {
      .about-info {
        grid-template-areas:
          'bioimage'
          'bio'
          'aw1'
          'aw2'
          'aw3';
        .bio-image {
          width: 200px;
          height: 200px;
          border-radius: 50%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
