#about-c {
  .about-logos {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 6rem;
  }
}

//Smart Phones
@media(max-width: 500px) {
  #about-c {
    display: none;
  }
}
